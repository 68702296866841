@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: 'Montserrat', sans-serif;
}

.carousel-root {
  width: 100%;
}
.css-1xuckoa-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #3b393a14 !important;
}
.css-19kzrtu {
  padding: 0px !important;
}
@media only screen and (max-width: 1023px) {
  .css-l4h7ic-MuiGrid-root > .MuiGrid-item {
    padding-left: 29.5px !important;
  }
}
.css-168o85v-MuiAutocomplete-root .MuiOutlinedInput-root {
   padding: 0px !important;
}

.slider-wrapper {
  width: 95% !important;
}
.styles_reactWaves__1M36F {
  width: 85%;
  display: inline-block;
  text-align: center;
  margin: 0 0;
  padding: 4px 0px 0px 0px;
}
@media (max-width: 600px) {
  .MuiDialogActions-root .MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root
  {
    background-color: #fe3d73;
  }
}
.css-6od3lo-MuiChip-label{
  text-underline-position: under !important;
  cursor:pointer !important;
}
.css-9iedg7{
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  text-underline-position: under !important;
  cursor:pointer !important;
}

@supports (-webkit-appearance: -apple-pay-button) { 
  .apple-pay-button {
      display: inline-block;
      -webkit-appearance: -apple-pay-button;
      height: 30px;
      margin-bottom: 5px;
      cursor: pointer;
  }
  .apple-pay-button-black {
      -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
      -apple-pay-button-style: white;
  }
  
  .apple-pay-button-white-with-line {
      -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
      display: inline-block;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 6px;
      padding: 10px 16px;
      box-sizing: border-box;
      min-width: 200px;
      min-height: 46px;
      max-height: 64px;
      font-size: 18px;
      line-height: 1.3333;
  }
  .apple-pay-button-black {
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
  }
  .apple-pay-button-white {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
  }
  .apple-pay-button-white-with-line {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
      border: .5px solid black;
  } 
}

